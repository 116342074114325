/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SET_USERLIKEWIDGETS,
} from "../actions/chat-userlike/types";

/*
 * ---------------------------------------------------------------------
 * initial userlike state
 * ---------------------------------------------------------------------
 */
const initialState = {
    // the support widgets if there is one.
    // support widget is always public
    supportWidgets: null,
    // widgets for rooms
    roomWidgets: null,
    // widgets for streams
    streamWidgets: null,
    // store mounted userlike api instances
    // one api can be re-used by e.g. several rooms
    mountedApis: {},
    // identifier of the current widget
    currentWidget: null,
};

/*
 * ---------------------------------------------------------------------
 * userlike reducer
 * ---------------------------------------------------------------------
 */
const chatUserlike = (state = initialState, action) => {
// TODO
    switch (action.type) {
        case SET_USERLIKEWIDGETS:
            return Object.assign({}, state, {
                supportWidgets: action.payload.supportWidgets,
                roomWidgets: action.payload.roomWidgets,
                streamWidgets: action.payload.streamWidgets,
            });

        default:
            return state;
    }
};

export default chatUserlike;
