/**
 * ---------------------------------------------------------------------
 * i18n index.js
 * ---------------------------------------------------------------------
 * @author seven-m.de
 * @copyright SEVEN M 2020
 * @version 2.8.0
 *
 * Frontend i18n simple handling
 * wraps around i18next / react-i18next
 *
 * ATTENTION:
 * i18n Shared Module works internal alway with locale codes!
 * Locale pathes for i18next are 2-letter code only!
 *
 * To test a language:
 *      http://[baseurl]/?lng=[2-letter-code]
 *
 * NODE MODULE DEPENDENCIES:
 * - i18next                            https://www.i18next.com/
 * - react-i18next                      https://github.com/i18next/react-i18next
 * - 18next-browser-languagedetector    https://github.com/i18next/i18next-browser-languageDetector
 * - i18next-http-backend
 */

import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, useTranslation } from 'react-i18next';

import React from 'react';

// Our personal language list as we need it
import { LANGUAGES } from "./language-list";
// load config
import {
    UI_LANGUAGES,
    DEFAULT_LANGUAGE,
    LOCALE_FOLDER_BASE
} from "../../config/i18n.js";

/**
 * initialize react-i18next
 */
export const init = async ( ) => {
    let debug = true;

    if( process.env.NODE_ENV !== "development" )
        debug = false;

    let def = getLanguageByLocale(DEFAULT_LANGUAGE).code2;

    // convert to 2-letter array for i18next options
    let supp = [];
    UI_LANGUAGES.forEach((item) => {
            let lang = getLanguageByLocale(item);
            supp.push(lang.code2);
        });
    return await i18next
        // load translation using http -> see /public/locales
        // learn more: https://github.com/i18next/i18next-http-backend
        .use(Backend)
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            fallbackLng: def,
            debug: debug,
            supportedLngs: supp,
            load: 'languageOnly',
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            backend: {
                loadPath: LOCALE_FOLDER_BASE +"/locales/{{lng}}/{{ns}}.json"
            }
        });
}

/**
 * Translate component function
 *
 * @param       {[type]} props [description]
 *
 * @return      {[type]}       [description]
 */
export function Translate( props ) {
    const { t } = useTranslation();

    let k = null;
    if( props.children !== undefined )
        k = props.children;

    if( props.i18nkey !== undefined )
        k = props.i18nkey;

    return (
            <React.Fragment>{t(k)}</React.Fragment>
        );
}

/**
 * Translate function for renderers
 *
 * @param       {string} key [description]
 *
 * @return      {string}       [description]
 */
export function t(key ) {
    return i18next.t(key);
}
/**
 * changes the current language
 * warps i18next function.
 *
 * @param  {string} lang 2-letter-code or locale code
 */
export const changeLanguage = async ( lang ) => {
    if( lang.length !== 2 )
        lang = getLanguageByLocale(lang).code2;

    await i18next.changeLanguage(lang);
}

/**
 * returns the current language Object defined in language-list.js
 *
 * @return {object} language/locale object
 */
export const currentLanguage = () => {
    let curr = i18next.language;
    let lang = null;

    if( curr.length > 2 ) {
        // this is a fix for chrome which stores during first setup a locale code in i18next.language
        // See Chrome DevTools / Application /local storage i18nextLng!
        lang = getLanguageByLocale( curr );
    } else {
        // Firefox stores only 2 letter language code
        lang = getLanguageByCode2( curr );
    }

    if( lang === undefined || lang === null )
    {
        console.warn("No language '"+curr+"' defined in list! Using fallback!");
        return getLanguageByLocale(DEFAULT_LANGUAGE);
    }

    return lang;
}

/**
 * returns a list of all defined languages in language-list.js
 *
 * @return {array} of language/locale objects
 */
export const getAll = () => {
    return LANGUAGES;
}

export const getActiveLanguages = () => {
    // convert to 2-letter array for i18next options
    let actives = [];
    UI_LANGUAGES.forEach((item) => {
            let lang = getLanguageByLocale(item);
            actives.push(lang);
        });
    return actives;
}

/**
 * get a language object.
 *
 * language Object defined in language-list.js
 * better use getLanguageByLocale or getLanguageByCode2
 *
 * @param  {string} key   [description]
 * @param  {string} value [description]
 *
 * @return {object} language/locale object
 */
export const getLanguageBy = (key, value) => {
    // FIXME: this creates a warning
    return LANGUAGES.find( (loc) => {
        if( loc[key] === value ) {
            return loc;
        }
        return null;
    });
}

/**
 * get a language object.
 * language Object defined in language-list.js
 *
 * @param  {string} value locale code
 *
 * @return {object} language/locale object
 */
export const getLanguageByLocale = ( value ) => {
    return getLanguageBy("locale", value);
}

/**
 * get a language object.
 * language Object defined in language-list.js
 *
 * @param  {string} value 2-letter-code
 *
 * @return {object} language/locale object
 */
export const getLanguageByCode2 = ( value ) => {
    return getLanguageBy("code2", value);
}
