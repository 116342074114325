import React from 'react';

import './error.css';

import { withRouter } from "react-router-dom";
import { withMatomo } from 'src/shared_modules/veu-visitors-core/components/common/matomo-tracking';

class ErrorPage extends React.Component {

    render() {
        let status = "500";
        let msg = "Internal Server Error";
        let desc = "The request could not processed on the server. The server was probably restarted as part of an update. Please try to reload the site soon.";

        if (this.props.status)
            status = this.props.status;

        if (this.props.msg)
            msg = this.props.msg;

        if (this.props.desc)
            desc = this.props.desc

        //console.error("trackEvent System show Error_" + status);
        this.props.trackEvent("System", "show", "Error_" + status);

        return (<section id="errorpage">
            <div className="error-container">
                <div className="error-col-left">
                    <div className="error-message-container">
                        <h3>Oops!</h3>
                        <h1 className="error-spacing">{status}</h1>
                        <p className="error-subline error-spacing">{desc}</p>
                        <br />
                        <span className="body1">
                            <p>{msg}</p>
                        </span>
                    </div>

                </div>
                <div className="error-image-container">
                    <img src="./assets/error.svg" alt="error" width="100%" height="100%" />
                </div>
            </div>
        </section>);
    }
}

export default withRouter(withMatomo(ErrorPage));
