/*
 * ---------------------------------------------------------------------
 * System Action Types
 * ---------------------------------------------------------------------
 */
export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_REDIRECT = "SET_REDIRECT";
export const CLEAR_REDIRECT = "CLEAR_REDIRECT";
export const SET_APP_STATE = "SET_APP_STATE";

export const SET_STARTLOC = "SET_STARTLOC";
export const SET_ROOM = "SET_ROOM";

export const SET_CDN = "SET_CDN";
export const SET_USERCOUNT = "SET_USERCOUNT";
export const SET_LANG = "SET_LANG";
