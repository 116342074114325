import {
    useMatomo
} from '@datapunt/matomo-tracker-react'

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    currentLanguage,
} from "src/shared_modules/i18n";

import { getMatomoConfig } from 'src/config/matomo';

import { DOCTITLE_PREFIX } from "src/constants";

const config = getMatomoConfig();

/**
 * ---------------------------------------------------------------------
 * withMatomo
 * ---------------------------------------------------------------------
 * HOC to provide tracking functionalty for the component inside.
 *
 * @see https://www.npmjs.com/package/@datapunt/matomo-tracker-react
 *
 * @param  {React.Component} MyComponent [description]
 * @return {[type]}      [description]
 */

export function withMatomo(MyComponent) {
    const HOComponent = (props) => {
        const { trackPageView, trackEvent } = useMatomo();
        var lang = currentLanguage().code2;
        var evName = "";

        if( props.veuEvent ) {
            evName = " - "+ props.veuEvent.name.en;
        }


        // our custom page tracking function
        const trackPage = ( ) => {
            trackPageView({
                documentTitle: DOCTITLE_PREFIX + " " + evName,
                customDimensions: [
                    { id: config.visitDimensions.eventId, value: props.event.path },
                    { id: config.visitDimensions.langId, value: lang },
                    { id: config.actionDimensions.isLoggedInId, value: props.isLoggedIn.toString() }
                ]
            });
        }

        const fireEvent = ( category, action, name, value = null ) => {
            trackEvent({
                documentTitle: DOCTITLE_PREFIX + " " + evName,
                category: category,
                action: action,
                name: name, // optional
                value: value, // optional, numerical value
                // href: 'https://LINK.TO.PAGE', // optional
                customDimensions: [
                    { id: config.actionDimensions.isLoggedInId, value: props.isLoggedIn.toString() },
                ],
            });

        }

        // TODO event tracking function

        return <MyComponent {...props} trackPageView={trackPage} trackEvent={fireEvent}/>;
    }

    HOComponent.propTypes = {
        veuEvent: PropTypes.object,
        isLoggedIn: PropTypes.bool,
    }

    const mapStateToProps = state => {
        return {
            veuEvent: state.event,
            isLoggedIn : state.user.loggedIn,
        };
    };
    return connect(
            mapStateToProps,
        )(HOComponent);

}
