/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SET_EVENT,
    UPDATE_EVENT,
    SET_CONFERENCE,
    SET_CHANNEL,
    SET_CHANNEL_URL,
    SET_GLOBALNOTICE,
} from "../actions/event/types";

/*
 * ---------------------------------------------------------------------
 * initial event state
 * ---------------------------------------------------------------------
 */
// Till now the event is null maybe in future we change this behaviour
const initialState = {
    // event core features mandatory
    enabled: true,
    fallback: false,
    accesslevel: "public",
    name: "",
    path: "",
    exhibitionEnabled: false, // if a live module is onAir this should be false
    onAir: false,
    estimatedLaunch: null,
    estimatedEnd: null,
    refreshAllTimeout: 30,
    languages: ["en-GB"],
    hasPlazzConvention: false,
    enablePreregister: false,
    enableMinimap: false,
    minimapPath: null,
    homeScreenPathes: null,
    logoPath: null,
    disableNewUserRegistration: false,  // disable registration of new users
    isStagingBase: false,
    stagingBaseFilter: "",
    isMiniEvent: false,
    enableRealtime: false,

    // event modules optional
    hasUserlike: false,

    //conferneces
    hasConference: false,
    liveConference: null,
    liveConferenceChannel: null,
    channelurl: null,

    hasGlobalNotice: false,
    globalNotice: null,

    //marketing consent button
    showMarketingButton: false,
    marketingButtonUrl: null,
    marketingButtonLabel: null,

    // plazz flags
    hasPlazzChat: false,

    visibleUserProfileFields: null,

    hasProtectionPin: false,

    // admin 3.4.0+, enableTutorial JED
    enableTutorial: false,

};

const assignEventCore = (state, payload) => {

    let core = {
        enabled: payload.enabled,
        fallback: payload.fallback,
        accesslevel: payload.accesslevel,
        name: payload.name,
        path: payload.path,
        exhibitionEnabled: payload.exhibitionEnabled, // if a live module is onAir this should be false
        onAir: payload.onAir,
        enablePreregister: payload.enablePreregister,
        disableScheduling: payload.disableScheduling,
        estimatedLaunch: payload.estimatedLaunch,
        estimatedEnd: payload.estimatedEnd,
        refreshAllTimeout: payload.refreshAllTimeout,
        disableNewUserRegistration: payload.disableNewUserRegistration,
        isStagingBase: payload.isStagingBase,
        stagingBaseFilter: payload.stagingBaseFilter,
        isMiniEvent: payload.isMiniEvent,
        enableRealtime: payload.enableRealtime,

        // event modules optinal
        hasPlazzConvention: payload.hasPlazzConvention,
        enableMinimap: payload.enableMinimap,
        minimapPath: payload.minimapPath,
        homeScreenPathes: payload.homeScreenPathes,
        logoPath: payload.logoPath,
        hasUserlike: payload.hasUserlike,
        hasConference: payload.hasConference,
        hasGlobalNotice: payload.hasGlobalNotice,
        showMarketingButton: payload.showMarketingButton,
        marketingButtonUrl: payload.marketingButtonUrl,
        marketingButtonLabel: payload.marketingButtonLabel,
        hasPlazzChat: payload.hasPlazzChat,
        languages: ["en-GB"],

        visibleUserProfileFields: payload.visibleUserProfileFields,

        hasProtectionPin: payload.hasProtectionPin,
        enableTutorial: payload.enableTutorial,
    };

    if (payload.languages && payload.languages.length > 0) {
        core["languages"] = payload.languages;
    }

    if (!payload.hasConference) {
        core["liveConference"] = null;
        core["liveConferenceChannel"] = null;
        core["channelurl"] = null;
    }

    if (!payload.hasGlobalNotice)
        core["globalNotice"] = null;

    return Object.assign({}, state, core);
}



/*
 * ---------------------------------------------------------------------
 * event reducer
 * ---------------------------------------------------------------------
 */
const event = (state = initialState, action) => {
    let available = true;

    switch (action.type) {
        case SET_EVENT:
            return assignEventCore(state, action.payload);

        case UPDATE_EVENT:
            return assignEventCore(state, action.payload);

        case SET_GLOBALNOTICE:
            if (action.payload === null)
                available = false;

            return Object.assign({}, state, {
                globalNotice: action.payload,
                hasGlobalNotice: available,
            });

        case SET_CONFERENCE:
            if (action.payload === null)
                available = false;

            return Object.assign({}, state, {
                liveConference: action.payload,
                hasConference: available,
            });

        case SET_CHANNEL:
            return Object.assign({}, state, {
                liveConferenceChannel: action.payload,
            });

        case SET_CHANNEL_URL:
            return Object.assign({}, state, {
                channelurl: action.payload
            });

        default:
            return state;
    }
};

export default event;
