
import { createMuiTheme } from '@material-ui/core/styles';

export const licenseeTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#646464',
            //light:,
            //dark:,
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#003399',
            //light:,
            //dark:,
            contrastText: '#ffffff',
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
            //tablet: ,
            //laptop: ,
            //desktop: ,
        },
    },
    //is like default material ui theme
    // with alot of bold
    typography: {
        fontFamily: [
            'Helvetica',
            'Arial',
            'sans-serif',
        ].join(','),
        h6 : {
            fontWeight: 600,
        }
    }
});

/* Breakpoints Anke
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
 */

/* Breakpoint Herbert
      xs: 0,
      sm: 600,  // logo shrinks
      md: 960,
      lg: 1040, // org 1280
      xl: 1280, // org 1920
*/
//https://material-ui.com/customization/default-theme/
