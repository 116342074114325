/*
 * ---------------------------------------------------------------------
 * imports
 * ---------------------------------------------------------------------
 */
import {
    SET_LOADING,
    SET_ERROR,
    SET_REDIRECT,
    CLEAR_REDIRECT,
    SET_ROOM,
    SET_STARTLOC,
    SET_APP_STATE,
    SET_CDN,
    SET_USERCOUNT,
    SET_LANG,
} from "src/shared_modules/veu-visitors-core/actions/system/types";

// legal appstates
import { APP_STATES } from "src/shared_modules/veu-visitors-core/constants";

/*
 * ---------------------------------------------------------------------
 * initial system state
 * ---------------------------------------------------------------------
 */
const initialState = {
    isLoading : true,
    error : null,
    redirectPath : null,
    appState : "init-event",
    room: null,
    startloc: null,
    cdn : null,
    community: {
        countglobal : 0,
        countroom : 0,
    },
    currentLang : "en-GB",
};

/*
 * ---------------------------------------------------------------------
 * internal
 * ---------------------------------------------------------------------
 */

const checkAppState = ( state, action ) => {
    if( APP_STATES.indexOf(action.payload) > -1 )
        return Object.assign({}, state, {
            appState: action.payload
        });

    //console.error("Illegal App State: " + action.payload);
    return state;
}

const setRedirect = ( state, action ) => {
    let path = "/" + action.to

    return Object.assign({}, state, {
        redirectPath: path
    });
}

/*
 * ---------------------------------------------------------------------
 * System reducer
 * ---------------------------------------------------------------------
 */
const system = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case SET_ERROR:
            return Object.assign({}, state, {
                error: action.error
            });
        case SET_REDIRECT:
            return setRedirect(state,action);
        case CLEAR_REDIRECT:
            return Object.assign({}, state, {
                redirectPath: null
            });
        case SET_ROOM:
            return Object.assign({}, state, {
                room: action.payload
            });
        case SET_STARTLOC:
            return Object.assign({}, state, {
                startloc: action.payload
            });
        case SET_CDN:
            return Object.assign({}, state, {
                cdn: action.payload
            });
        case SET_USERCOUNT:
            return Object.assign({}, state, {
                community: {
                    countglobal: action.payload.countglobal,
                    countroom: action.payload.countroom,
                }
            });
        case SET_LANG:
            return Object.assign({}, state, {
                currentLang: action.payload
            });
        case SET_APP_STATE:
            return checkAppState( state, action );

        default:
            return state;
    }
};

export default system;
