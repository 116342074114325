/*
 * ---------------------------------------------------------------------
 * i18n.js config file for Frontend
 * ---------------------------------------------------------------------
 * list of available locales use for langauges configuration
 * internal i18n module uses always these locale codes
 *
 * This list is reduced to the countries where the languge is comes from.
 *
 * This file needs to be placed in:
 *
 * [rootfrontend]/src/config/i18n.js
 */

/**
 * this list contains all languages supported in the ui and backend
 * for translating labels and texts.
 *
 * @type {Array}
 */
export const UI_LANGUAGES = [
    "de-DE",
    "en-GB"
];

/**
 * is used as i18next fallback language
 * @type {String}
 */
export const DEFAULT_LANGUAGE = "de-DE";

export const LOCALE_FOLDER_BASE = process.env.PUBLIC_URL;
