/*
 * ---------------------------------------------------------------------
 * imports
 * ---------------------------------------------------------------------
 */
/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */


import {
    SET_USER,
    SET_PROFILE,
    CLEAR_USER,
    SET_USER_LOCATION,
    SET_PREREGISTERED,
    SET_KOMDAT_LEAD,
    SET_KOMDAT_USERLIKEWASOPENED,
    SET_MINIEVENTS_LOGGEDIN,
    ADD_ALREADY_ACCESSED_SCENE,
} from "src/shared_modules/veu-visitors-core/actions/user/types";

/*
 * ---------------------------------------------------------------------
 * initial user state
 * ---------------------------------------------------------------------
 */
const initialState = {
    // mongo db id
    _id: null,
    // can be anonym, local, plazz ... more to come
    type: null,
    loggedIn: false,
    minieventsLoggedIn: false,
    alreadyAccessedScenes: [],
    // for anonym (not logged in users)
    uuid : null,
    username: null,
    location : {
        eventPath: null,
        currentPath: null,
        room: null,
        location: null,
    },
    // global profile data is filled by authentificator
    // if type = local we can edit it.
    profile : {
        formOfAdress: null,
        title: null,
        firstname : null,
        lastname : null,
        email : null,
        company : null,
        position : null,
        photo: null,
        thumbnail: null,
        city : null,
        phone : null,
        aboutme : null,
        country : null,
        division: null,
        department: null,
    },
    isTmpPwd: false,
    created: null,
    lastUpdate: null,
    // TODO groups define access rights
    groups: [],
    // plazz app api login data if type is plazz
    // only delivered in dev or test env
    plazz: {
        session : null,
        id : null,
        forcePrivacySetup: false,
    },
    // this is updated by a separate call
    isPreregistered: false,

    komdatLead: null,
    // TODO add missing profile fields we need
    // eg groups and profile data
    // role ??!
};

const assignUser = ( state, action ) => {
    let user = action.payload.user;
    let assign;
    if( user === null || user === undefined )
        return state;

    assign = {
        _id: user._id,
        type: user.type,
        loggedIn : user.loggedIn,
        minieventsLoggedIn : user.minieventsLoggedIn,
        username: user.username,
        created: user.created,
        lastUpdate: user.lastUpdate,
        isTmpPwd: user.isTmpPwd,

        location : {
            eventPath: user.location.eventPath,
            currentPath: user.location.currentPath,
            room: user.location.room,
            location: user.location.location,
        },

        profile : {
            formOfAdress: user.profile.formOfAdress,
            title: user.profile.title,
            firstname : user.profile.firstname,
            lastname : user.profile.lastname,
            email : user.profile.email,
            company : user.profile.company,
            position: user.profile.position,
            photo: user.profile.photo,
            thumbnail: user.profile.thumbnail,
            city : user.profile.city,
            phone : user.profile.phone,
            aboutme : user.profile.aboutme,
            country : user.profile.country,
            division: user.profile.division,
            department: user.profile.department,
        },

        // optional only if type === plazz otherwise its null also only if dev or test
        plazz : user.plazz,
    };

    return Object.assign({}, state, assign);
}

const assignProfile = ( state, action ) => {
    let profile = action.payload;
    let assign;
    if( profile === null || profile === undefined )
        return state;

    assign = {
        profile : profile,
    };
    return Object.assign({}, state, assign);
}

const assignKomdatLead = ( state, action ) => {
    let komdatLead = action.payload;
    if( komdatLead === null || komdatLead === undefined )
        return state;

    return Object.assign({}, state, {
        komdatLead : komdatLead,
    });
}

const userlikeWasOpened = ( state, action ) => {
    if( state.komdatLead === null || state.komdatLead === undefined )
        return state;

    return Object.assign({}, state, {
        ...state,
        komdatLead : {
            ...state.komdatLead,
            openUserlike: false,
        },
    });
}

const addAlreadyAccessedScene = ( state, action ) =>  {
    return Object.assign({}, state, {
        ...state,
        alreadyAccessedScenes : state.alreadyAccessedScenes.concat(action.payload.sceneName)
    });
}

/*
 * ---------------------------------------------------------------------
 * User reducer
 * ---------------------------------------------------------------------
 */
const user = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return assignUser(state, action);
        case SET_USER_LOCATION:
            return Object.assign({}, state, {
                location: action.payload.location
            });
        case CLEAR_USER:
            return Object.assign({}, state, initialState );
        case SET_PROFILE:
            return assignProfile(state, action);
        case SET_PREREGISTERED:
            return Object.assign({}, state, {
                isPreregistered: action.payload
            });
        case SET_KOMDAT_LEAD:
            return assignKomdatLead(state, action);
        case SET_KOMDAT_USERLIKEWASOPENED:
            return userlikeWasOpened(state, action);
        case SET_MINIEVENTS_LOGGEDIN:
            return Object.assign({}, state, {
                minieventsLoggedIn: action.payload
            });
        case ADD_ALREADY_ACCESSED_SCENE:
            return addAlreadyAccessedScene(state, action);

        default:
             return state;
    }
};

export default user;
